<template>
  <div>
    <!-- breadcrumb -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">理財課程</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                理財課程
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div
          class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
        >
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger" id="online">線上課程</h2>
          <span class="shape shape-right bg-info"></span>
        </div>
        <div class="row mb-4 mb-md-8">
          <div class="col-sm-6 col-lg-3 col-xs-12" v-for="item in onlineCourse" :key="item.id" style="margin-bottom: 1.56rem;">
            <div class="card" style="height: 100%; margin-bottom: 0;">
              <div class="position-relative">
                <img
                  class="card-img-top adjust-height"
                  :src="item.img"
                  alt="Card image"
                />
              </div>
              <div :class="`border-${item.themeClass}`"
                class="card-body border-top-5 px-3 rounded-bottom"
              >
                <h3 class="card-title">
                  <a :class="`text-${item.themeClass}`"
                    class="text-capitalize d-block text-truncate"
                    :href="item.url"
                    target="_blank"
                    >{{ item.title }}</a
                  >
                </h3>
                <p>{{ item.content }}</p>
                <div class="border-top-0 pt-2">
                  <ul class="list-unstyled mb-1">
                    <li
                      class="d-flex justify-content-between align-items-baseline p-0 border-0"
                    >
                      <!-- <a href="#" class="btn btn-white text-uppercase disabled"
                        >課程已售完</a
                      > -->
                      <a
                        target="_blank"
                        :href="item.url" :class="`btn-${item.themeClass}`"
                        class="btn text-white text-uppercase"
                        >報名此課程</a
                      >
                      <div class="pricing-price" :class="`text-${item.themeClass}`">
                        <span class="">{{ item.special }}</span>
                        <small class="d-block"
                          ><del class="opacity-70">{{ item.cost }}</del></small
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
        >
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger" id="offline">線下實體課程</h2>
          <span class="shape shape-right bg-info"></span>
        </div>
        <div class="row">
          <div class="col-sm-6 col-lg-3 col-xs-12" v-for="item in downCourse" :key="item.id" style="margin-bottom: 1.56rem;">
            <div class="card" style="height: 100%; margin-bottom: 0;">
              <div class="position-relative">
                <img
                  class="card-img-top adjust-height"
                  :src="item.img"
                  alt="Card image"
                />
              </div>
              <div
                :class="`border-${item.themeClass}`"
                class="card-body border-top-5 px-3 rounded-bottom"
              >
                <h3 class="card-title">
                  <a
                    :class="`text-${item.themeClass}`"
                    class="text-capitalize d-block text-truncate"
                    :href="item.url"
                    target="_blank"
                    >{{ item.title }}</a
                  >
                </h3>
                <p>
                  {{ item.content }}
                </p>
                <div class="border-top-0 pt-2">
                  <ul class="list-unstyled mb-1">
                    <li
                      class="d-flex justify-content-between align-items-baseline p-0 border-0"
                    >
                      <!-- <a href="#" class="btn btn-white text-uppercase disabled"
                        >課程已售完</a
                      > -->
                      <a
                        target="_blank"
                        :href="item.url" :class="`btn-${item.themeClass}`"
                        class="btn text-white text-uppercase"
                        >報名此課程</a
                      >
                      <div class="pricing-price" :class="`text-${item.themeClass}`">
                        <span class="">{{ item.special }}</span>
                        <small class="d-block"
                          ><del class="opacity-70">{{ item.cost }}</del></small
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/kidz.css";
</style>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'course',
  data () {
    return {
      onlineCourse: [],
      downCourse: []
    }
  },
  created () {
    const vm = this
    Promise.all([vm.getOnlineClass(), vm.getOfflineClass()]).then(() => {
      vm.$nextTick(() => {
        const { anchor } = this.$route.query
        if (anchor) {
          const target = document.querySelector(`#${anchor}`)
          const top = target.getBoundingClientRect().top
          const navbarHeight = document.querySelector('nav.navbar').getBoundingClientRect().height
          console.log(top - navbarHeight)
          window.scrollTo(0, top - navbarHeight)
        }
      })
    })
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getOnlineClass () {
      return new Promise((resolve) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/course/list`

        vm.axios.post(api, {
          token: tokenGenerator(),
          type: ['3'],
          categoryId: ['5'],
          page: 1,
          pageSize: 4
        })
          .then((res) => {
            vm.onlineCourse = res.data.content
            vm.onlineCourse.forEach((item, index) => {
              const i = index + 1
              if (i % 4 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 4 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 4 === 3) {
                return (item.themeClass = 'danger')
              } else {
                return (item.themeClass = 'info')
              }
            })
            resolve()
          })
      })
    },
    getOfflineClass () {
      return new Promise((resolve) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/course/list`

        vm.axios.post(api, {
          token: tokenGenerator(),
          type: ['3'],
          categoryId: ['6'],
          page: 1,
          pageSize: 4
        })
          .then((res) => {
            vm.downCourse = res.data.content
            vm.downCourse.forEach((item, index) => {
              const i = index + 1
              if (i % 4 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 4 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 4 === 3) {
                return (item.themeClass = 'danger')
              } else {
                return (item.themeClass = 'info')
              }
            })
            resolve()
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-height {
  height: 220px;
  object-fit: cover;
}
.test {
  color: white;
}
</style>
